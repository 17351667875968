#UsersTable_filter,
#AgencyTable_filter,
#ProfileWebsiteTable_filter,
#ComplianceScoringTable_filter,
#AssessmentProfileWebsiteTable_filter {
    display: none;
}

@media only screen and (max-width: 749px) {
    .form-wizard-custom-footer-btn1 {
        display: none;
    }

    .form-wizard-custom-footer-btn2 {
        display: flex;
    }
}

@media only screen and (min-width: 750px) {
    .form-wizard-custom-footer-btn1 {
        display: block;
    }

    .form-wizard-custom-footer {
        display: flex;
        position: relative;
    }

    .form-wizard-custom-footer-btn2 {
        display: none;
    }
}


/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
}

.icon_in_circle {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0 0 2px #888;
    padding: 0.5em 0.6em;
}

.truncate2 {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}





.terminal {
    border-radius: 5px 5px 0 0;
    position: relative;
}

.terminal .top {
    background: #E8E6E8;
    color: black;
    padding: 5px;
    border-radius: 5px 5px 0 0;
}

.terminal .btns {
    position: absolute;
    top: 7px;
    left: 5px;
}

.terminal .circle {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 15px;
    margin-left: 2px;
    border-width: 1px;
    border-style: solid;
}

.title {
    text-align: center;
}

.red {
    background: #EC6A5F;
    border-color: #D04E42;
}

.green {
    background: #64CC57;
    border-color: #4EA73B;
}

.yellow {
    background: #F5C04F;
    border-color: #D6A13D;
}

.clear {
    clear: both;
}

.terminal .body {
    background: black;
    color: #7AFB4C;
    padding: 8px;
    overflow: auto;
}

.space {
    margin: 25px;
}

.shadow {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .4)
}


.right-tabs .nav {
    float: right;
    border-bottom: 0px;
}

.right-tabs .nav li {
    float: left
}

.right-tabs .tab-content {
    float: left;
    border-top: 1px solid #ddd;
    margin-top: -1px;
}

.align-left {
    text-align: left;
  }


  .btn-smallX{
    font-size: 0.9em !important;
    padding: 0 0.9em !important;
    margin-bottom: 4px;
}
